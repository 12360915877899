//格式化时间
export function getTime(time) {
	let today = new Date(time)
	// 获取年、月、日、时、分、秒
	var year = today.getFullYear();
	var month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
	var day = today.getDate();
	var hours = today.getHours();
	var minutes = today.getMinutes();
	var seconds = today.getSeconds();

	// 格式化输出
	return year + "-" +
		(month < 10 ? "0" : "") + month + "-" +
		(day < 10 ? "0" : "") + day + " " +
		(hours < 10 ? "0" : "") + hours + ":" +
		(minutes < 10 ? "0" : "") + minutes + ":" +
		(seconds < 10 ? "0" : "") + seconds;

}