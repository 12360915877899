import { createStore } from "vuex";
// 导入 vuex 持久化存储
import createPersistedState from "vuex-persistedstate";

export default createStore({
  modules: {},
  plugins: [
    // 配置持久化数据存储
    createPersistedState({
      key: "rabbit-client-pc",
    }),
  ],
});
