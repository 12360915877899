<template>
	<router-view :key="$route.fullPath" />
</template>
<style>
	.warp-img ol>li {
		list-style: decimal;
		margin-left: -23px;
	}

	.warp-img ul>li {
		list-style-type: disc;
		margin-left: 20px;
	}
</style>
<!-- <style lang="less">
.el-popper.custom-1.is-light {
  background: rgba(#000, 0.29);
  border: none;
}
.el-popper.custom-2.is-light {
  background: rgba(#000, 0.7);
  border: none;
}
.el-menu--horizontal {
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0 !important;
  border-right: none;
}

</style> -->
<script setup>
	// import request from "@/utils/request";
	// request.get("/message").then((res) => {
	//   console.log("hello msw", res);
	// });
	import {
		onMounted
	} from "vue";
	import {
		checkSafe
	} from "@/api/Home";

	onMounted(() => {
		// 后管统计网站访问量
		const url = window.location.href
		let referer = document.referrer
		if(url.length > 240){ // 数据库限制page长度255
			url = url.substring(0, 240)
		}
		if (referer == '') {
			referer = '直接访问'
		} else if (referer.indexOf('?') != -1) {
			referer = referer.substring(0, referer.indexOf('?'))
		}

		const query = {
			client: "PC",
			page: url,
			source: referer
		}
		checkSafe(query)


		function getAllTextNodes(element) {
			let textArr = []
			// 递归函数，用于收集文本节点  
			function collectTextNodes(el) {
				// 遍历当前元素的所有子节点  
				el.childNodes.forEach(node => {
					// 检查节点类型是否为文本节点  
					if (node.nodeType === Node.TEXT_NODE) {
						textArr.push(node.textContent.trim())
					} else if (node.nodeType === Node.ELEMENT_NODE) {
						// 如果是元素节点，则递归调用  
						collectTextNodes(node);
					}
					// 忽略其他类型的节点，如注释节点等  
				});
			}

			// 从给定的元素开始收集文本节点  
			collectTextNodes(element);

			// 返回所有文本节点 
			return textArr;
		}
		let isHandlingClickEvent=false 
		document.addEventListener('click', e => {
			isHandlingClickEvent=true
			setTimeout(()=>{
				isHandlingClickEvent=false
			},1000)
			let target = e?.target
			let buttonName = ' '
			if (target) {
				//如果是图片或者是视频，则获取其URL
				if (target.nodeName === 'IMG' || target.nodeName === 'VIDEO') {
					//如果URL长度过长，则说明是base64
					if(target.currentSrc.length<200){
						buttonName = target.currentSrc
					}
				} else {
					//其他情况则取子元素内部最短的文本
					let filterArr = getAllTextNodes(e.target).filter(v => Boolean(v))
					buttonName = filterArr[0]
					filterArr.forEach(v => {
						buttonName = v.length < buttonName.length ? v : buttonName
					})
				}
			}
			localStorage.setItem('buttonName', buttonName)
		}, true)
		//兼容浏览器前进或者后退
		document.addEventListener('popstate', (e)=>{
			if(!isHandlingClickEvent){
				localStorage.setItem('buttonName', '返回')
			}
		});
	})
</script>

<style lang="less">
	/* @import "./assets/styles/variables"; */
	// #app {
	//   -webkit-font-smoothing: antialiased;
	//   -moz-osx-font-smoothing: grayscale;
	// }
</style>